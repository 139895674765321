@tailwind base;
@tailwind components;
@tailwind utilities;

//@import '~video.js/dist/video-js.css';
@import 'video.js/dist/video-js.css';
// DEFAULT OVERRIDES

@font-face {
  font-family: "SCFont";
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

html, body {
  @apply bg-light dark:bg-dark #{!important};
}

body {
  @apply font-sans text-base;
  font-family: "SCFont";
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-xl;
}

h3 {
  @apply text-lg #{!important};
}

h3 {
  @apply text-base;
}

select {
  -o-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

hr {
  @apply sc-divider;
}

table {
  @apply w-full sc-text-default text-sm;
  td, th {
    @apply px-2 py-4 text-left;
  }

  td:first-child {
    @apply pl-10;
  }

  //td:last-child {
  //  @apply flex justify-end;
  //}

  thead {
    @apply sticky top-0 rounded-b-3xl dark:bg-dark bg-light-tertiary z-[1];
  }

  tbody {
    tr {
      @apply sc-hover font-light;
    }

    tr:not(:last-child) {
      @apply sc-divider;
    }

    tr:first-child {
      td:first-child {
        @apply rounded-tl-3xl;
      }

      td:last-child {
        @apply rounded-tr-3xl;
      }
    }

    tr:last-child {
      td:first-child {
        @apply rounded-bl-3xl;
      }

      td:last-child {
        @apply rounded-br-3xl;
      }
    }
  }
}

pre {
  @apply p-4 bg-light-secondary dark:bg-dark rounded-md max-w-[80vw] overflow-ellipsis overflow-hidden shadow-md;
  .number-line {
    color: #adadaf;
  }

  .string {
    @apply text-green-700 dark:text-green-400;
  }

  .number {
    @apply text-yellow-700 dark:text-yellow-200;
  }

  .boolean {
    color: #0097f1;
  }

  .null {
    color: #727990;
  }

  .key {
    @apply sc-text-default;
  }
}

a {
  @apply text-blue-700 dark:text-primary;
}


input[type="checkbox"] {
  @apply scale-125;
}

input[type="radio"] {
  @apply h-5 w-5 grid place-content-center;
  place-content: center;
}

input[type="radio"]::before {
  @apply h-5 w-5 border rounded-full border-[#707070];
  content: "";
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #FFE600;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

// chrome autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #000000 !important;
}

@media (prefers-color-scheme: dark) {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #FFE600 !important;
  }
}


::-webkit-scrollbar {
  @apply w-1.5 h-1.5;
}

::-webkit-scrollbar-track {
  @apply rounded-full bg-light-tertiary;
}

::-webkit-scrollbar-thumb {
  @apply rounded-full bg-primary;
}

//::-webkit-scrollbar-thumb:hover {
//  @apply bg-primary;
//}

.even-color {
  @apply bg-light dark:bg-[#2E3033];
}

.not-even-color {
  @apply bg-[#F3F3F3] dark:bg-[#212224];
}


// MAPBOX

.mapboxgl-ctrl-group {
  @apply bg-light dark:bg-dark-secondary;
  button {
    @apply rounded-full;
  }
}

.mapboxgl-ctrl-attrib {
  @apply hidden;
}

.mapboxgl-popup-content {
  @apply sc-card pt-0 sc-text-default border-none max-h-[400px] min-w-[500px] h-full overflow-y-auto #{!important};
}

.mapboxgl-popup-tip {
  @apply border-t-light dark:border-t-dark-secondary border-b-light dark:border-b-dark-secondary;
}

.mapboxgl-popup-close-button {
  @apply hidden;
}

.mapbox-compass {
  @apply p-1 rounded-full bg-light dark:bg-dark-secondary #{!important};
  svg {
    @apply ml-1;
  }
}

//.mapboxgl-canvas-container {
//  height: 100% !important;
//  width: 100% !important;
//}

//.mapboxgl-canvas {
//  @apply box-border h-full w-full #{!important};
//}


// MAPBOX GEOCODER

.mapboxgl-ctrl-geocoder--input {
  @apply sc-input text-black dark:text-white #{ !important };
  padding-left: 2.5em;
  padding-right: 2.5em;
}

.mapboxgl-ctrl-geocoder {
  background: transparent !important;
  width: 300px;

  .suggestions {
    @apply sc-card;
  }
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a {
  @apply bg-[#E6E6E6] dark:bg-[#424447] rounded-lg z-[999];
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  @apply sc-hover rounded-lg my-1 z-[999];
}

.mapboxgl-ctrl-geocoder--icon {
  @apply fill-black dark:fill-white;
}

.mapboxgl-ctrl-geocoder--powered-by {
  display: none !important;
}

.mapboxgl-ctrl-geocoder--button {
  background: transparent;
}

.mapboxgl-ctrl-geocoder--suggestion {
  @apply sc-text-default z-[999];
}

.suggestions-wrapper {
  @apply z-[999];
}

// SWIPER
.swiper-pagination-bullet {
  @apply bg-primary #{!important};
  //background: var(--swiper-theme-color);
}

.swiper-button-prev, .swiper-button-next {
  --swiper-navigation-size: 25px;
  @apply text-primary bg-light dark:bg-dark-secondary h-10 w-10 shadow-sm rounded-full;
}

// SECTRA
.sc-text-default {
  @apply text-black dark:text-gray-200;
}

.sc-text-default-reverse {
  @apply text-white dark:text-black;
}

.sc-text-primary {
  @apply text-primary;
}

.sc-main-container {
  @apply h-full w-full max-w-full overflow-auto rounded-3xl scroll-smooth;

}

.sc-shadow-bottom {
  @apply shadow-[0_7px_5px_-5px_rgba(0,0,0,0.1)];
}

.sc-hover {
  @apply hover:bg-[#E6E6E6] hover:dark:bg-[#424447] cursor-pointer;
}

.sc-card {
  @apply rounded-3xl bg-light dark:bg-dark-secondary p-4 2xl:p-6 shadow-md;
}


.sc-card-reverse {
  @apply rounded-3xl bg-light-secondary dark:bg-dark p-4 shadow-md;
}

.sc-divider, .sc-divider-vertical {
  @apply border-light-secondary dark:border-dark;
}

.sc-divider {
  @apply border-b-[1px] w-full;
}

.sc-divider-vertical {
  @apply border-r-[1px] h-full;
}

.sc-border-primary {
  @apply border-primary;
}

.sc-filter-container {
  label {
    @apply text-base font-light sc-text-default;
  }

  @apply flex flex-col gap-3 justify-start items-start min-w-[250px];
}

.sc-button-text {
  @apply bg-transparent border-transparent shadow-none;
}


.sc-btn,
.sc-btn-reverse,
.sc-btn-primary,
.sc-btn-danger,
.sc-btn-warn,
.sc-btn-success,
.sc-btn-primary-reverse,
.sc-btn-danger-reverse,
.sc-btn-primary-static {
  @apply shadow-sm text-xs rounded-full px-4 py-2 font-medium border disabled:opacity-50 disabled:cursor-not-allowed hover:scale-[1.02] hover:disabled:scale-100 duration-100;
}

.sc-btn,
.sc-btn-primary {
  @apply text-black dark:text-gray-300  bg-light-tertiary dark:bg-dark-tertiary  border-dark/10 dark:border-light/5;
}

.sc-btn-danger {
  @apply text-danger bg-light-tertiary dark:bg-dark-tertiary border-danger;
}

.sc-btn-warn {
  @apply text-warn bg-light-tertiary dark:bg-dark-tertiary border-warn;
}

.sc-btn-success {
  @apply text-success bg-light-tertiary dark:bg-dark-tertiary border-success;
}


.sc-btn-reverse {
  @apply sc-text-default bg-light dark:bg-dark border-dark/10 dark:border-light/5;
}

.sc-btn-primary {
  @apply hover:bg-primary hover:text-black;
}

.sc-btn-primary-reverse {
  @apply bg-light dark:bg-dark hover:bg-primary hover:text-black hover:dark:text-primary border-primary #{!important};
}

.sc-btn-danger-reverse {
  @apply bg-light dark:bg-dark hover:bg-danger hover:dark:bg-danger text-danger hover:text-white border-danger #{!important};
}

.sc-btn {
  @apply hover:border-dark/20 hover:dark:border-light/50;
}

.sc-btn-primary-static {
  @apply text-black border border-dark/10 dark:border-light/5 bg-primary;
}

.sc-input {
  @apply min-w-[150px] invalid:outline-danger disabled:opacity-50 disabled:cursor-not-allowed shadow-md text-sm transition-all duration-300 focus:outline-dashed focus:outline-offset-4 outline-dark/30 dark:outline-light/10 rounded-xl bg-light-secondary dark:bg-dark px-4 py-2 font-thin border border-dark/10 dark:border-light/30 hover:border-dark/30 hover:dark:border-light/10 dark:text-white text-black read-only:cursor-not-allowed read-only:transition-none read-only:outline-0 read-only:hover:border-dark/10 read-only:hover:dark:border-light/30 ;
}

.sc-input-container {
  @apply flex flex-col gap-3;
}

.sc-badge {
  @apply rounded-full px-2 py-1 inline-block shadow-md bg-white dark:bg-black sc-text-default text-sm border border-dark/10;
}

.sc-skeleton {
  @apply bg-light-tertiary dark:bg-dark-tertiary border border-light-secondary dark:border-dark-secondary animate-pulse inline-flex;
}

.sc-stats-container {
  @apply bg-light-secondary dark:bg-dark rounded-3xl flex flex-col justify-center gap-1 text-dark dark:text-white border border-gray-200 dark:border-light/10 p-4 shadow-md;
}

.sc-animated-close-button {
  @apply rotate-0 hover:rotate-90 duration-300;
}

.tooltip-bottom-to-top {
  @apply z-50 [transform:perspective(0)_translateZ(0)_rotateX(-10deg)] group-hover:[transform:perspective(0px)_translateZ(0)_rotateX(0deg)] absolute top-8 origin-top transform rounded opacity-0 transition-all duration-300 group-hover:opacity-100;
}

.tooltip-top-to-bottom {
  @apply z-50 [transform:perspective(0)_translateZ(0)_rotateX(10deg)] group-hover:[transform:perspective(0px)_translateZ(0)_rotateX(0deg)] absolute bottom-8 origin-bottom transform rounded opacity-0 transition-all duration-300 group-hover:opacity-100;
}

.sc-add-from-list-title {
  @apply text-lg mb-4;
}

.sc-options-container-arrow-up {
  @apply w-0 h-0 border-[10px] border-l-transparent border-r-transparent border-t-0 border-b-light-secondary dark:border-b-dark;
}

.table-filters {
  @apply flex flex-wrap items-center gap-2;
}


// ANIMATIONS
.modal-animation-in {
  @apply motion-scale-in-[0.5] motion-translate-x-in-[-25%] motion-translate-y-in-[25%] motion-opacity-in-[0%] motion-rotate-in-[-10deg] motion-blur-in-[5px] motion-duration-[0.15s] motion-duration-[0.25s]/scale motion-duration-[0.25s]/translate motion-duration-[0.35s]/rotate;
}

.modal-animation-out {
  @apply motion-scale-out-[0.5] motion-translate-x-out-[25%] motion-translate-y-out-[25%] motion-opacity-out-[0%] motion-rotate-out-[10deg] motion-blur-out-[5px] motion-duration-[0.15s] motion-duration-[0.25s]/scale motion-duration-[0.25s]/translate motion-duration-[0.35s]/rotate;
}

@keyframes swing-out-top-bck {
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0;
  }
}

// QUILL

.ql-toolbar {
  @apply sc-input rounded-3xl rounded-b-none border-b-0 border-dark/10 dark:border-light/30 w-full flex justify-center #{!important};
}

.ql-container {
  @apply sc-input rounded-3xl rounded-t-none transition-none border-dark/10 dark:border-light/30 cursor-text #{!important};
}

.ql-snow {
  a {
    @apply text-primary #{!important};
  }
}
